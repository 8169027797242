import { useState, useEffect, Dispatch, SetStateAction } from 'react';

type useStringLocalStorageReturnType<T> = [T, Dispatch<SetStateAction<T>>];

function useStringLocalStorage<T extends string>(key: string, initialValue: T): useStringLocalStorageReturnType<T> {

  const [storedValue, setStoredValue] = useState<T>(() => {
    if (typeof window === "undefined") {
      return initialValue;
    }

    try {
      const item = window.localStorage.getItem(key) as T;
      return item ? item : initialValue;
    } catch (error) {
      console.log(error);
      return initialValue;
    }
  });

  useEffect(() => {
    setStoredValue(storedValue);
    window.localStorage.setItem(key, storedValue);
  }, [key, storedValue])

  return [storedValue, setStoredValue];
}

export default useStringLocalStorage;