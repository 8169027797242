import React from "react";
import CircularProgress from "@mui/material/CircularProgress";

const style = {
  padding: "50px 0",
  textAlign: "center" as const,
};

function Loader() {
  return (
    <div style={style}>
      <CircularProgress />
    </div>
  );
}

export default Loader;
