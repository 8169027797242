import axios from 'axios';

const baseUrl =  "https://game-api.metastake.app/"//"http://localhost:9000/"//
const AuthApi = {
  login: (uuid:string)=>{ 
    let loginUrl = "login_discord?uuid="+uuid;
    return baseUrl.concat(loginUrl.toString());
  },
  loginGoogle: (uuid:string)=>{ 
    let loginUrl = "login_google?uuid="+uuid;
    return baseUrl.concat(loginUrl.toString());
  },
  logout: ()=>{  
    return "/"
  },
  message:()=>{
    return "Dimenxion Wallet Verfication"
  },
  message_main:()=>{
    return "Main Wallet"
  },
  message_withdraw:()=>{
    return "Withdraw"
  },
  message_withdraw_nfts:(user_id:string,main_wallet:string)=>{
    return "Withdraw : " + user_id + " to Main Wallet : " + main_wallet
  },
  add_wallet:(user_id:string,pubkey:string,sig:string)=>{
    const searchParams = new URLSearchParams({ user_id ,pubkey,sig});
    return axios.get(`${baseUrl}add_wallet?${searchParams.toString()}`);
  },
  add_wallet_eth:(user_id:string,pubkey:string,sig:string)=>{
    const searchParams = new URLSearchParams({ user_id ,pubkey,sig});
    return axios.get(`${baseUrl}add_wallet_eth?${searchParams.toString()}`);
  },
  deleteWallet:(user_id:string,pub_key:string)=>{
    const searchParams = new URLSearchParams({ user_id ,pub_key});
    return axios.get(`${baseUrl}delete_wallet?${searchParams.toString()}`);
  },
  setMainWallet:(user_id:string,pub_key:string,sig:string)=>{
    const searchParams = new URLSearchParams({ user_id ,pub_key,sig});
    return axios.get(`${baseUrl}set_mainWallet?${searchParams.toString()}`);
  },
  setMainWallet_eth:(user_id:string,pub_key:string,sig:string)=>{
    const searchParams = new URLSearchParams({ user_id ,pub_key,sig});
    return axios.get(`${baseUrl}set_mainWallet_eth?${searchParams.toString()}`);
  },
  depositTx:(user_id:string,pub_key:string,token:string,amount:string)=>{
    const searchParams = new URLSearchParams({ user_id ,pub_key,token,amount});
    return axios.get(`${baseUrl}deposit?${searchParams.toString()}`);
  },
  depositTx_eth:(user_id:string,pub_key:string,token:string,amount:string)=>{
    const searchParams = new URLSearchParams({ user_id ,pub_key,token,amount});
    return axios.get(`${baseUrl}deposit_eth?${searchParams.toString()}`);
  },
  withdraw:(user_id:string,token:string,sig:string,amount:string)=>{
    const searchParams = new URLSearchParams({ user_id ,sig,token,amount});
    return axios.get(`${baseUrl}withdraw?${searchParams.toString()}`);
  },
  withdraw_eth:(user_id:string,token:string,sig:string,amount:string)=>{
    const searchParams = new URLSearchParams({ user_id ,sig,token,amount});
    return axios.get(`${baseUrl}withdraw-eth?${searchParams.toString()}`);
  },
  depositNftsTx:(user_id:string,tokens:string,pub_key:string)=>{
    const searchParams = new URLSearchParams({ user_id ,tokens,pub_key});
    return axios.get(`${baseUrl}deposit_nfts?${searchParams.toString()}`); 
  },
  withdrawNfts:(user_id:string,tokens:string,pub_key:string,signature:string)=>{
    const searchParams = new URLSearchParams({ user_id ,tokens,pub_key,signature});
    return axios.get(`${baseUrl}withdraw_nfts?${searchParams.toString()}`);
  },
  depositNftsTxPoly:(user_id:string,tokens:string,pub_key:string)=>{
    const searchParams = new URLSearchParams({ user_id ,tokens,pub_key});
    return axios.get(`${baseUrl}deposit_nfts?${searchParams.toString()}`); 
  },
  withdrawNftsPoly:(user_id:string,tokens:string,pub_key:string,signature:string)=>{
    const searchParams = new URLSearchParams({ user_id ,tokens,pub_key,signature});
    return axios.get(`${baseUrl}withdraw_nfts?${searchParams.toString()}`);
  },
  getWalletNfts:(user_id:string,pub_key:string)=>{
    const searchParams = new URLSearchParams({ user_id ,pub_key});
    return axios.get(`${baseUrl}current_wallet_assets?${searchParams.toString()}`);
  },
  getWalletNftsPoly:(user_id:string,pub_key:string)=>{
    const searchParams = new URLSearchParams({ user_id ,pub_key});
    return axios.get(`${baseUrl}current_wallet_assets_poly?${searchParams.toString()}`);
  },
  getEscrowNfts:(user_id:string)=>{
    const searchParams = new URLSearchParams({ user_id});
    return axios.get(`${baseUrl}escrow_assets?${searchParams.toString()}`);
  },
  getEscrowNftsPoly:(user_id:string)=>{
    const searchParams = new URLSearchParams({ user_id});
    return axios.get(`${baseUrl}escrow_assets_poly?${searchParams.toString()}`);
  },
  setNetworkPref:(user_id:string,network:string)=>{
    const searchParams = new URLSearchParams({ user_id,network});
    return axios.get(`${baseUrl}set_main_network?${searchParams.toString()}`);
  }
}



export default AuthApi;