import { useRef, useState } from "react";



import { useClickOutside } from "hooks";
import HeaderLogo from "../assets/img/png/headerlogo.png";
import "./Header.scss";
import DiscordAuth from "./DiscordAuth"
import { useWindowSize } from "rooks";

function Header() {
  
  const navBarRef = useRef<HTMLUListElement>(null);
  const { innerWidth } = useWindowSize();
  const isDesktop = innerWidth && innerWidth >= 992;
  useClickOutside<HTMLUListElement>(navBarRef, () => {
    if (isExpanded) {
      setIsExpanded(false);
    }
  });

  const [isExpanded, setIsExpanded] = useState(false);

  const toggleIsExpanded = () => {
    setIsExpanded((isExpanded) => !isExpanded);
  };

  return (
    <>
      <section className="w-100 navbar-wrapper position-relative py-lg-3 ">
        <div className="container d-flex justify-content-between align-items-center ">
          <li className="mx-xxl-3 mx-1 nav-list-items p-2 d-inline-block active " >
            <a href="https://dimenxion.io/">Home</a>
          </li>
          <li  className="custom d-inline-block">
            <img className="nav-logo" src={HeaderLogo} alt="HeaderLogo" />
          </li>
          {isDesktop&&(
            <li className="mx-xxl-3 mx-1 nav-list-items p-2 d-inline-block ">
              <DiscordAuth />
            </li>
          )}               
        </div>
      </section>
       

      
    </>
  );
}

export default Header;
