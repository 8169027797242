import { AnyAction, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { notify } from 'react-notify-toast';

import Api from 'api';

import { NFT } from 'types'

type WatchlistState = {
  isLoading: boolean,
  data: NFT[]
};


const initialState: WatchlistState = {
  isLoading: false,
  data: []
};

function isSetUserAction(action: AnyAction) {
  return action.type.startsWith('user/');
}

const watchListSlice = createSlice({
  name: 'watchlist',
  initialState,
  reducers: {
    addToWatchList: (state, action: PayloadAction<NFT>) => {
      state.data = [...state.data, action.payload]

      return state;
    },
    deleteFromWatchList: (state, action: PayloadAction<NFT>) => {
      state.data = state.data.filter((item) => item.symbol !== action.payload.symbol)

      return state;
    },
    updatePriceWatchList: (state, action: PayloadAction<NFT>) => {
      state.data = state.data.map((item) => {
        if (item.symbol === action.payload.symbol) {
          return {
            ...item,
            price: action.payload.price
          }
        }

        return item;
      })

      return state;
    },

    setWatchList: (state, action: PayloadAction<NFT[]>) => {
      state.data = action.payload
      state.isLoading = false

      return state;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(isSetUserAction, (state, action) => {

        if (action.type.endsWith('setUserRequests')) {
          state.isLoading = true;
        } else if (action.type.endsWith('setUserFailure')) {
          state.isLoading = false;
        }

        return state;
      })
  }
})


export const addToWatchListAsync = (pub_key: string, nft: NFT, price: number) => (dispatch) => {
  Api.addToWatchList(pub_key, nft.symbol, price).then((response) => {
    const { value, message } = response.data;

    if (value === 1) {
      notify.show(message, "success");
      dispatch(addToWatchList({ ...nft, price }));
      return;
    }

    notify.show(message, "error");

  }).catch(() => {
    dispatch(addToWatchList(nft));
    notify.show("something wrong", "error");
  });
}

export const deleteFromWatchListAsync = (pub_key: string, nft: NFT) => (dispatch) => {
  Api.deleteFromWatchList(pub_key, nft.symbol).then((response) => {
    const { value, message } = response.data;

    if (value === 1) {
      notify.show(message, "success");
      dispatch(deleteFromWatchList(nft));
      return;
    }

    notify.show(message, "error");

  }).catch(() => {
    notify.show("something wrong", "error");
  });
}

export const updatePriceWatchListAsync = (pub_key: string, nft: NFT, price: number) => (dispatch) => {
  Api.updatePriceWatchList(pub_key, nft.symbol, price).then((response) => {
    const { value, message } = response.data;

    if (value === 1) {
      notify.show(message, "success");
      dispatch(updatePriceWatchList({ ...nft, price }));
      return;
    }

    notify.show(message, "error");

  }).catch(() => {
    notify.show("something wrong", "error");
  });
}

export const { addToWatchList, deleteFromWatchList, updatePriceWatchList, setWatchList } = watchListSlice.actions
export default watchListSlice.reducer
