import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";

import "normalize.css";
import { CookiesProvider } from 'react-cookie';
import WalletProvider from "providers/WalletProvider";
import ThemeProvider from "providers/ThemeProvider";
import store from "store";
import App from "App";
import { Web3ContextProvider } from 'libs/components/Web3ContextProvider'
import "translation";

import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";
if (window.ethereum) {
  window.ethereum.autoRefreshOnNetworkChange = false
}

// Node polyfills required by WalletConnect are no longer bundled with webpack
window.Buffer = Buffer
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <WalletProvider>
        <Web3ContextProvider>
          <ThemeProvider>
            <BrowserRouter>
              <CookiesProvider>
                <App />
              </CookiesProvider>
            </BrowserRouter>
          </ThemeProvider>
        </Web3ContextProvider>
      </WalletProvider>
    </Provider>
  </React.StrictMode>
);
