import { useState, useEffect,useRef } from "react";
import { useAppSelector } from "hooks";
import Button from '@mui/material/Button';
import AuthApi from "./DiscordConst";
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useCookies } from 'react-cookie';
import "pages/Escrow/Escrow.css"

const DiscordAuth = () => {

  const [cookies, setCookie, removeCookie] = useCookies(['Dimenxion']);

  const [width, setWidth] = useState(0);
  const [position, setPosition] = useState({left: 0, top: 0});
  const menuRef = useRef<HTMLButtonElement>(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    removeCookie('Dimenxion')
    setAnchorEl(null);
  };
  const handleCloseModal = () => {
    setAnchorEl(null);
  };
 
  //useUser();
  const user = useAppSelector((store) => store.user.data);
  let id = user.id;
  useEffect(() => {
    const menuHTML = menuRef.current ? menuRef.current.getBoundingClientRect(): {width: 0, height: 0, left: 0, top: 0};
    setWidth(
      menuHTML.width
    );
    setPosition({
      left: menuHTML.left  ,
      top: menuHTML.top + menuHTML.height,
    });
  }, [user,cookies,cookies.Dimenxion,removeCookie])

  return (
  <>
    <div className="discord-div">
    {!id? (<></>
    ):(
      <>{cookies.Dimenxion ?(<>
        <Button ref={menuRef} onClick={handleClick} className ="gradient-border">
          <Avatar alt="User Avatar" src = {user.avatar} className="discord-button-logo" sx={{marginRight:'5px'}}/>
          <span >{user.username}</span>
      </Button>
      <Menu
      id="basic-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={handleCloseModal}
      MenuListProps={{
        'aria-labelledby': 'button',
      }}
      PaperProps={{ sx: { width: width, left: `${position.left}px !important`} }}
      
    >
      <MenuItem onClick={handleClose} style={{textAlign:'center'}}>Logout</MenuItem>
    </Menu>
    </>):(<></>)}
      
    </>
    )}
    </div>
  </>
  )


};

export default DiscordAuth;

