import axios from 'axios';
import getApiBaseUrl from '../utils/getApiBaseUrl';

const baseUrl = getApiBaseUrl();

const Api = {
    
    search: async function (keyword: string) {
        const searchParams = new URLSearchParams({ keyword });
        return axios.get(`${baseUrl}/search?${searchParams.toString()}`);
    },

    getUser: async function (pub_key: string) {
        const searchParams = new URLSearchParams({ pub_key });
        return axios.get(`${baseUrl}/get_user?${searchParams.toString()}`);
    },

    getUserDiscord: async function (user_id : string ) {
      const searchParams = new URLSearchParams({ user_id });
      return axios.get(`https://game-api.metastake.app/get_auth?${searchParams.toString()}`);
    },

    

    addToWatchList: async function (pub_key: string, symbol: string, price: number) {
        const searchParams = new URLSearchParams({ pub_key, symbol, price: String(price) });
        return axios.get(`${baseUrl}/add_to_watch_list?${searchParams.toString()}`);
    },


    deleteFromWatchList: async function (pub_key: string, symbol: string) {
        const searchParams = new URLSearchParams({ pub_key, symbol });
        return axios.get(`${baseUrl}/delete_from_watch_list?${searchParams.toString()}`);
    },

    updatePriceWatchList: async function (pub_key: string, symbol: string, price: number) {
        const searchParams = new URLSearchParams({ pub_key, symbol, price: String(price) });
        return axios.get(`${baseUrl}/update_price_watch_list?${searchParams.toString()}`);
    },

    withdrawSol: function (pub_key: string, amount: number) {
        const searchParams = new URLSearchParams({ pub_key, amount: String(amount) });
        return axios.get(`${baseUrl}/withdraw_sol?${searchParams.toString()}`);
    },

    withdrawNft: function (pub_key: string, mint: string) {
        const searchParams = new URLSearchParams({ pub_key, mint });
        return axios.get(`${baseUrl}/withdraw_nft?${searchParams.toString()}`);
    },

    getUserNfts: function (pub_key: string) {
        const searchParams = new URLSearchParams({ pub_key });
        return axios.get(`${baseUrl}/get_nft_by_user?${searchParams.toString()}`);
    }

}

export default Api;