import { RefObject, useEffect, useRef } from 'react';

const useClickOutside = <T extends HTMLElement = HTMLElement>(
  elRef: RefObject<T>,
  callback: (event: MouseEvent) => void
) => {
  const callbackRef = useRef(null);

  callbackRef.current = callback;

  useEffect(() => {
    const handleClick = (event) => {
      if (!elRef?.current?.contains(event.target) && callbackRef.current) {
        callbackRef.current(event);
      }
    };

    document.addEventListener('click', handleClick, true);

    return () => {
      document.removeEventListener('click', handleClick, true);
    };
  }, [elRef, callbackRef]);
};

export default useClickOutside;