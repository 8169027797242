import { useState } from "react";
import { useAppSelector } from "hooks";
import Deposit from "components/Deposit";
import Withdraw from "components/Withdraw";
import "./Escrow.css";
import "../../components/NftList/Modal.scss";

import logo from "assets/icons/solana.svg";
import rainlogo from "assets/icons/raindrops.svg";
import usdc from "assets/icons/usd.svg";
import photon from "assets/icons/logophoton.png";
import Box from "@mui/material/Box";
import ButtonGroup from "@mui/material/ButtonGroup";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";

const EscrowDetails = () => {
  const user = useAppSelector((store) => store.user.data);
  const tokens = [
    { name: "Sol", icon: logo },
    { name: "Rain", icon: rainlogo },
    { name: "Usdc", icon: usdc },
    { name: "Photon", icon: photon },
  ];

  const [selectedToken, setSelectedToken] = useState(tokens[0]);

  const handleChange = (event) => {
    const token = tokens.find((t) => t.name === event.target.value);
    setSelectedToken(token);
  };

  return (
    <>
      {user && (
        <div className="escrow-details">
          <hr />
          <h3
            style={{
              display: "inline-flex",
              alignItems: "center",
              alignContent: "center",
            }}
          >
            {" "}
            TOKENS{" "}
          </h3>
          <hr />
          <br />
          <FormControl variant="filled" fullWidth >
            <Select
              labelId="token-select-label"
              value={selectedToken.name}
              onChange={handleChange}
              input={
                <OutlinedInput
                  notched
                  label="Token"
                  style={{
                    backgroundColor: "#512da8", // Change this to match the desired background color
                  }}
                />
              }
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "center",
                },
                PaperProps: {
                  style: {
                    backgroundColor: "#512da8", // Change this to match the desired background color
                  },
                },
              }}
              renderValue={(value) => (
                <div style={{ color: "white" }}>{value}</div>
              )}
            >
              {tokens.map((token) => (
                <MenuItem key={token.name} value={token.name}>
                  <div style={{ color: "white" }}>{token.name}</div>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <br />
          <br />
          <div>
            <div
              style={{
                display: "inline-flex",
                alignItems: "center",
                alignContent: "center",
              }}
              className="para"
            >
              {selectedToken.name} Balance : &nbsp;
              {user.balances[selectedToken.name] ? (
                <div>{user.balances[selectedToken.name]}&nbsp;</div>
              ) : (
                <div>--&nbsp;</div>
              )}
              &nbsp;&nbsp;
              <img
                className="logo-solana"
                src={selectedToken.icon}
                alt={selectedToken.name}
              />
            </div>
            <br />
            <Box sx={{ mt: 5 }}>
              <ButtonGroup disableElevation variant="contained">
                <Deposit token={selectedToken.name} />
                <Withdraw token={selectedToken.name} />
              </ButtonGroup>
            </Box>
          </div>
        </div>
      )}
    </>
  );
};

export default EscrowDetails;

