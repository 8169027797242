import { configureStore } from '@reduxjs/toolkit'

import userReducer from './userSlice'
import watchListReducer from './watchListSlice'
import historyWatchListReducer from './historyWatchListSlice'

const store = configureStore({
  reducer: {
    user: userReducer,
    watchList: watchListReducer,
    historyWatchList: historyWatchListReducer,
  }
})


export type RootState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch

export default store
