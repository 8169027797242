import React, { useEffect } from "react";

import { useStringLocalStorage } from "hooks";
import ThemeContext, { THEMES, ThemeType } from "./ThemeContext";

interface Props {
  children: JSX.Element;
}

const ThemeProvider: React.FC<Props> = ({ children }) => {
  const [theme, setTheme] = useStringLocalStorage<ThemeType>("theme", "dark");

  function switchTheme() {
    setTheme((theme) => (theme === THEMES.dark ? THEMES.light : THEMES.dark));
  }

  useEffect(() => {
    switch (theme) {
      case THEMES.dark:
      default:
        document.body.classList.add("dark-theme");
        break;
      case THEMES.light:
      
        document.body.classList.remove("dark-theme");
        break;
    }
  }, [theme]);

  return (
    <ThemeContext.Provider value={{ theme, switchTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};

export default ThemeProvider;
