import React from "react";
import { Route, Routes } from "react-router-dom";
import Notifications from "react-notify-toast";

import Layout from "components/Layout";
import Home from "pages/Home";
import WatchList from "pages/WatchList";
import Escrow from "pages/Escrow";
import Explore from "pages/Explore";
import { useUser } from "hooks";
import AOS from "aos";
import "aos/dist/aos.css";
import "animate.css";
import "./App.scss";
import "./App.css";
function App() {
  AOS.init({ once: true });
  // listen to publicKey value and set current user
  useUser();

  return (
    <div className="App">
      <Notifications />
      <Routes>
        <Route
          path="/"
          element={
            <Layout>
              <Escrow />
            </Layout>
          }
        />
      </Routes>
    </div>
  );
}

export default App;
