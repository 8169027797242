import React from "react";

import Header from "./Header";
import Container from "@mui/material/Container";
import Footer from "./Footer";

import "./Layout.css";

interface Props {
  children: JSX.Element;
}

const Layout: React.FC<Props> = ({ children }) => {
  return (
    <div className="wrapper">
      <div>
        <Header />
        <Container sx={{ py: 5 }} maxWidth="lg">
          {children}
        </Container>
      </div>
      <Footer />
    </div>
  );
};

export default Layout;
