import { useEffect, useCallback,useState } from "react";
import { useAppSelector } from "hooks";
import AuthApi from "../../components/DiscordConst";
import { getUserAsync } from 'store/userSlice';
import { useAppDispatch } from 'hooks/useAppStore';
import { notify } from "react-notify-toast";
import "./Escrow.css";
import Modal from "@mui/material/Modal";
import { WalletMultiButton} from "@solana/wallet-adapter-react-ui";
import { useWallet } from "@solana/wallet-adapter-react";
import { WalletNotConnectedError } from "@solana/wallet-adapter-base";
import Button from "@mui/material/Button";
import "./Modal.scss"
import IconButton from '@mui/material/IconButton';
import YesIcon from "components/yes_but";
import Box from '@mui/material/Box';
import ButtonGroup from '@mui/material/ButtonGroup';
import HomeIcon from '@mui/icons-material/AccountBalanceWalletOutlined';

const ConnectWallet = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { publicKey,signMessage} = useWallet();
  const dispatch = useAppDispatch();
  const user = useAppSelector((store) => store.user.data);
  const user_id = useAppSelector((state) => state.user.data.id);
  const sign = useCallback(async () => {
    if (!publicKey) throw new WalletNotConnectedError();
    var enc = new TextEncoder();
    const msg = await signMessage(enc.encode(AuthApi.message()))
    //const { binary_to_base58 } = require('base58-js')
    const api_str = Buffer.from(msg).toString('hex')
    console.log('testt')
    AuthApi.add_wallet(user_id.toString(),publicKey.toString(),api_str).then((response)=>{
      const { value,message } = response.data;
      if (value === 1){
        dispatch(getUserAsync(user_id));
        notify.show(message, "success");
        closeModal()
      }else{notify.show(message, "error");}
    })
  },[dispatch, publicKey, signMessage, user_id])

  const openModal = () => {
    setIsOpen(true);
  };
  const closeModal = () => {
    setIsOpen(false);
  };

  const isWalletVerified = useCallback(() => {
    if (!publicKey) return false
    console.log(user.wallets.indexOf(publicKey.toString()),'is_ver')
    if (user.wallets.indexOf(publicKey.toString()) > -1){
      return false
    }
    return true
  },[publicKey, user.wallets])
  useEffect(()=>{
    if(publicKey){openModal()}
  },[publicKey,isWalletVerified,user])

  return(<>

  <Box sx={{ mt: 5 }}>
    <ButtonGroup disableElevation variant="contained" sx={{display:'flex-inline'}}>
      <WalletMultiButton />
    </ButtonGroup>
  </Box>
  
  <br/>
  {isWalletVerified()?(<>
    <div style={{display:'inline-flex',alignItems:'center',alignContent:'center'}}>
    <p className="para"> To verify your wallet Please Click on the button ! &nbsp;</p>
    <Button
          variant="contained"
          disableElevation
          onClick={sign}//handleSendTransaction}
        >
          Verify Wallet
    </Button>
    </div>
  
  
  </>):(<></>)}

  
  <Modal
    open={isOpen}
    onClose={closeModal}
    //sx={{alignItems:'center',textAlign:'center'}}
  >
    <Box className="modal-box">
        {isWalletVerified()?(
        <Button
          variant="contained"
          disableElevation
          onClick={sign}//handleSendTransaction}
        >
          Verify Wallet
        </Button>
        ):(
        <div style={{alignItems:"center"}}>
          <span style={{alignItems:"center",textAlign:'center',display:'inline'}}> 
            <h2 style={{color:"green"}}> Current Wallet already Verified ! </h2>
            <h4> To Add Another Wallet : </h4>
              1 - Disconnect Current Wallet
            <br/>2 - Select New Wallet From The Browser Extention
            <br/>3 - Connect New Wallet 
            <br/> <span style={{display:'inline-flex',alignItems:'center',alignContent:'center'}}>4 - To Set a Wallet as Main click the icon : <HomeIcon /></span>
            <br/> (Important The Wallet Must be Connected to be set as Main)
          </span>
          <br/>
            <IconButton onClick={closeModal}>
              <YesIcon className="logo-quest" style={{fill:"green"}}/>
            </IconButton>
          
        </div>
         )}
    </Box>
  </Modal>
  
  
  
  
  </>)


}

export default ConnectWallet;