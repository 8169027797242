import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { NFT } from 'types'

type HistoryWatchlistState = NFT[];

const initialState: HistoryWatchlistState = [];

const historyWatchListSlice = createSlice({
  name: 'historyWatchList',
  initialState,
  reducers: {
    setHistoryWatchList: (state, action: PayloadAction<NFT[]>) => {
      state = action.payload
      return state;
    }
  }
})

export const { setHistoryWatchList } = historyWatchListSlice.actions
export default historyWatchListSlice.reducer
