import React, { useEffect } from "react";
import { Carousel } from "react-responsive-carousel";
import Grid from "@mui/material/Grid";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./sty.scss"
// export const AppContext = createContext([]);

const UnStake = (props: { userData: { staked_nfts: any; }; selectedStake: any; selecteStakeHendler: (arg0: any) => void; }) => {
  const userData = props.userData.staked_nfts;
  console.log(userData, "userData");
  useEffect(() => {}, [userData, props.selectedStake]);
  return (
    //   <AppContext.Provider value={{user, setUser}}>
    <>
      <div className="escrow-details">
      
      <div style={{display:'inline-flex',alignItems:'center',alignContent:'center'}}  className='para'> Nfts you can Deposit : {"  "} &nbsp; {userData ? userData.length : 0 }</div>
    <Carousel
       // autoPlay
        transitionTime={1000}
        interval={3000}
        infiniteLoop
        showThumbs
        showStatus={false}
        showArrows={false}
      >
        {userData && userData.length > 0 ? (
          userData.map((item, index) => {
            const isSelected = props.selectedStake.find(
              (stake, index) => stake.id === item.id
            );
            return (
              <Grid
                
                sx={{
                  color: "#ffffff !important",
                  background: "rgba(255, 255, 255, 0.05) !important",
                  backdropFilter: "blur(58.9096px) !important",
                  border: " 1px dotted #ffffff !important",
                  //   width: fit-content;
                  //   margin: 0;
                  borderRadius: "16px !important",
                  minHeight: "350px !important",
                  justifyContent: "center",
                }}
                className={`pointer ${ isSelected ? 'stake-selected' : ''}`}
                title={isSelected && isSelected.id ? "Remove" : "Add"}
                onClick={() => props.selecteStakeHendler(item)}
              >
                <img
                  style={{ width: "100%", borderRadius: "20px" }}
                  src={item.image_url}
                  alt="nfts"
                />
              </Grid>
            );
          })
        ) : (
          <div
            style={{
              // height: "300px",
              color: "#ffffff ",
              background: "rgba(255, 255, 255, 0.05)",
              backdropFilter: "blur(58.9096px) ",
              border: " 1px dotted #ffffff",
              //   width: fit-content;
              //   margin: 0;
              borderRadius: "16px",
              minHeight: "350px",
              justifyContent: "center",
            }}
          />
        )}
      </Carousel>
      </div>
    </>

    //   </AppContext.Provider>
  );
};

export default UnStake;
