import AuthApi from "../../components/DiscordConst";
import { useEffect } from "react";
import Button from "@mui/material/Button";
import { SiDiscord,SiGoogle } from 'react-icons/si';
import { useCookies } from 'react-cookie';
import { v4 as uuidv4 } from 'uuid';

const Login = () =>{

  const [cookies,setCookie] = useCookies(['Dimenxion']);
  const url = ()=>{
    
    if (!cookies.Dimenxion){
      let d = new Date();
      d.setTime(d.getTime() + (604800*1000));
      let uuid =  uuidv4();
      console.log('what the fuck !!! ')
      setCookie('Dimenxion',uuid, { path: '/',expires:d });
      return AuthApi.login(uuid)
    }
    return AuthApi.login(cookies.Dimenxion)
 
  }
  const urlGoogle = ()=>{
    if (!cookies.Dimenxion){
      let d = new Date();
      d.setTime(d.getTime() + (604800*1000));
      let uuid =  uuidv4();
      console.log('what the fuck !!! ')
      setCookie('Dimenxion',uuid, { path: '/',expires:d });
      return AuthApi.loginGoogle(uuid)
    }
    return AuthApi.loginGoogle(cookies.Dimenxion)
  }
  const handleClick = () => {
    window.location.href = url()
  };

  const handleclickGoogle = () =>{
    window.location.href = urlGoogle()

  }

  useEffect(() => {

    
  }, [cookies,cookies.Dimenxion,setCookie])

  
  return(
    <>
    <Button onClick={handleClick} variant="outlined" startIcon={<SiDiscord />} sx={{borderColor:'white',color:'white',marginTop:'2%'}} >
              Login With Discord
    </Button>
    <br/>
    <Button onClick={handleclickGoogle} variant="outlined" startIcon={<SiGoogle />} sx={{borderColor:'white',color:'white',marginTop:'2%'}}>Login With Google</Button>
    </>)

}
export default Login;