import { useEffect, useCallback,useState } from "react";
import { useAppSelector } from "hooks";
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import AuthApi from "../../components/DiscordConst";
import { getUserAsync } from 'store/userSlice';
import { useAppDispatch } from 'hooks/useAppStore';
import { notify } from "react-notify-toast";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import "./Escrow.css";
import Modal from "@mui/material/Modal";
import Box from '@mui/material/Box';
//import  {ReactComponent as YesIcon}  from "assets/icons/yes-checkmark-icon.svg";
//import  {ReactComponent as NoIcon}  from "assets/icons/no-cross-icon.svg";
import "./Modal.scss"
import Button from "@mui/material/Button";
import YesIcon from "components/yes_but";
import NoIcon from "components/no_but";
//import HomeIcon from '@mui/icons-material/Home';
import WalletIcon from '@mui/icons-material/AccountBalanceWallet';
import HomeIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import { useWeb3React } from '@web3-react/core';

function truncate(input:string) {
  
  const len = input.length - 6
  return input.substring(0, 6) + '...' + input.substring(len);
 
  
};

const WalletsEth = () =>{
  const { chainId, account, isActive ,provider} = useWeb3React()
  const [toDelete, setToDelete] = useState<string>();
  const [isOpen, setIsOpen] = useState(false);
  const [asMain,setAsMain] = useState<string>();
  const [isOpenMain, setIsOpenMain] = useState(false);
  const user = useAppSelector((store) => store.user.data);
  const dispatch = useAppDispatch();
  const [WalletLengthCont, setWalletLengthCont] = useState(false);

  const signMess = async ()=>{
    const resp = await provider.getSigner().signMessage("test");
    console.log("signed",resp)
  }
  const deleteWallet = useCallback(async (item:string)=>{
    AuthApi.deleteWallet(user.id,item).then((response)=>{
      const { value,message } = response.data;
      console.log(message,"message_delete");
      if (value === 1){
        console.log("testtstststststststststs");
        dispatch(getUserAsync(user.id));
        notify.show(message, "success");
        closeModal()
      }else{notify.show(message, "error");}
      
    })
  },[dispatch, user.id]);


  const setMainWallet = useCallback(async (item:string)=>{
    
    const msg = await await provider.getSigner().signMessage(AuthApi.message_main())
    //const { binary_to_base58 } = require('base58-js')
    const api_str = msg//Buffer.from(msg).toString('hex')
    AuthApi.setMainWallet_eth(user.id.toString(),account.toString(),api_str).then((response)=>{
      const { value,message } = response.data;
      if (value === 1){
        dispatch(getUserAsync(user.id.toString()));
        notify.show(message, "success");
        closeModal()
      }else{notify.show(message, "error");}
    })


  },[dispatch, account, user.id]);

  const walletsLenght = useCallback(()=>{
    if (user.wallets_eth.length > 0){
      return true;
    }
    return false
  },[user.wallets_eth])

  const openModal = (pub_key:string) => {
    setToDelete(pub_key)
    setIsOpen(true);
  };
  const closeModal = () => {
    setIsOpen(false);
    setIsOpenMain(false);
  };

  useEffect(() => {
    setWalletLengthCont(walletsLenght())
  }, [user, walletsLenght]);

  const openModalMain = (pub_key:string) => {
    setAsMain(pub_key);
    setIsOpenMain(true);
  };
  
  const isCurrentWallet = useCallback ((item:string)=>{
    if (!account) return false
    return account.toString() === item && account.toString() !== user.main_wallet_eth;
  },[account, user.main_wallet_eth])

  const isMainWallet = useCallback((item:string)=>{
    return item === user.main_wallet_eth
  },[user.main_wallet_eth])



  return(<>
  < >
      {WalletLengthCont?(<><hr/><p > Verified Wallets : </p><hr/></>):(<></>)}
      
      
      <List disablePadding={true} sx={{alignItems:'center',textAlign:'center'}}>
      {user.wallets_eth &&(user.wallets_eth.map((item) => 
          <>
            
            <ListItem style={{alignItems:"center",textAlign:'center',display:'inline'}} >
              <div className="list-wallets">
                {truncate(item)}
                &nbsp;
                <IconButton aria-label="delete" onClick={()=>{openModal(item)}}>
                  <DeleteIcon />
                </IconButton>
                &nbsp;
                {isCurrentWallet(item)?(<>
                  <IconButton aria-label="Main" onClick={()=>{openModalMain(item)}}>
                  <HomeIcon />
                </IconButton>
                </>):(<>
                {isMainWallet(item)?(<>
                  <WalletIcon/>
                </>):(<></>)}
                </>)}
              </div>
            <Modal
              open={isOpen}
              onClose={closeModal}
              // className="modal-box"
              //className="modal "
            >
            <Box className="modal-box">
              <h2 className="modal-box__details-title">
                Are you sure you want to delete this wallet ? 
              </h2>
                <br/>
              <p className="modal-box__details-price">
                {toDelete}
              </p>
              <br/>
              <div style={{alignItems:"center",flex:"row"}}>
                <IconButton onClick={()=>{deleteWallet(toDelete)}}>
                    <YesIcon className="logo-quest" style={{fill:"green"}}/>
                </IconButton>
                <Button color="error" disableElevation onClick={closeModal} className="modal-box_button">
                  <NoIcon className="logo-quest" style={{fill:"red"}}/>
                </Button>
              
              </div>
            </Box>


            </Modal>
            <Modal
              open={isOpenMain}
              onClose={closeModal}
            >
            <Box className="modal-box">
              <h2 className="modal-box__details-title">
                  Do you want to set this wallet as The Main Wallet ? 
              </h2>
              <div style={{alignItems:"center",flex:"row"}}>
                <IconButton onClick={()=>{setMainWallet(asMain)}}>
                    <YesIcon className="logo-quest" style={{fill:"green"}}/>
                </IconButton>
                <Button color="error" disableElevation onClick={closeModal} className="modal-box_button">
                  <NoIcon className="logo-quest" style={{fill:"red"}}/>
                </Button>
              
              </div>
            </Box>
            </Modal>
            
            </ListItem>
          </>))}    
          
      </List>
    </>
  </>)



}

export default WalletsEth;