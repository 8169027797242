import {useEffect , useState,useCallback} from "react";
import { useAppSelector } from "hooks";
import "./Escrow.css";
import "../../components/NftList/Modal.scss";
import Wallets from "./Wallets";
import Login from "./Login"
import ConnectWallet from "./ConnectWallets";
import { useCookies } from 'react-cookie';
import EscrowDetails from "./EscrowDetails";
import EscrowDetailsEth from "./EscrowDetailsEth";
import { useWindowSize } from "rooks";
import DiscordAuth from "components/DiscordAuth";
import Stake from "components/Stake";
import UnStake from "components/Stake/UnStake";
import { useWallet } from "@solana/wallet-adapter-react";

import AuthApi from "components/DiscordConst";
import { notify } from "react-notify-toast";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import { WalletNotConnectedError } from "@solana/wallet-adapter-base";
import { Message, Transaction, Connection} from "@solana/web3.js";
import logo from "assets/icons/solana.svg";
import logoPoly from "assets/icons/matic-token.png";
import WalletsEth from "./WalletsEth";

import ConnectWalletEth from "./ConnectEthWallets"
import { useWeb3React } from '@web3-react/core';

const Escrow = () => {
  const { t } = useTranslation();
  const [selectedStake, setSelectedStake] = useState([]);
  const [selectedStakeNfts, setSelectedStakeNfts] = useState([]);
  const {publicKey,sendTransaction,signMessage} = useWallet();
  const [walletNfts,setWalletNfts] = useState([]);
  const [walletNftsPoly,setWalletNftsPoly] = useState([]);
  const [escrowNfts,setEscrowNfts] = useState([]);
  const [escrowNftsPoly,setEscrowNftsPoly] = useState([]);
  const [cookies, setCookie ] = useCookies(['Dimenxion']);
  const user = useAppSelector((store) => store.user.data);
  const [transaction, setTransaction] = useState(null);
  const { innerWidth } = useWindowSize();
  const [tabValue, setTabValue] = useState("Solana");
  const [mainNet,setMainNet] = useState(null);
  const { account ,provider} = useWeb3React();
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const handleSetPref = (newValue)=>{
    setMainNet(newValue);
    AuthApi.setNetworkPref(user_id,newValue).then((response)=>{
      const { value,message} = response.data;
        if (value === 1){
          notify.show(message,"success");
        }else{
          notify.show(message, "error");
        }
    
    })
  }
  const main_wallet = useAppSelector((state) => state.user.data.main_wallet);
  const main_wallet_eth = useAppSelector((state)=>state.user.data.main_wallet_eth)
  const isDesktop = innerWidth && innerWidth < 992;
  useEffect(()=>{
    console.log(user.id,'id_useEffect')
    console.log(cookies.Dimenxion,'Cookie')
    
    if(cookies.Dimenxion){
      AuthApi.getEscrowNfts(cookies.Dimenxion).then((response)=>{
        const { value,data,message} = response.data;
        if (value === 1){
          console.log("wallet Assets",data);
          setEscrowNfts(data);
        }else{
          notify.show(message, "error");
        }
      })
      AuthApi.getEscrowNftsPoly(cookies.Dimenxion).then((response)=>{
        const { value,data,message} = response.data;
        if (value === 1){
          console.log("wallet Assets",data);
          setEscrowNftsPoly(data);
        }else{
          notify.show(message, "error");
        }
      })
      if(account){
        AuthApi.getWalletNftsPoly(cookies.Dimenxion,account.toString()).then((response)=>{
          const { value,data,message} = response.data;
          if (value === 1){
            console.log("wallet Assets",data);
            setWalletNftsPoly(data);
          }else{
            notify.show(message, "error");
          }
        })
      }
      if(publicKey){
        AuthApi.getWalletNfts(cookies.Dimenxion,publicKey.toString()).then((response)=>{
          const { value,data,message} = response.data;
          if (value === 1){
            console.log("wallet Assets",data);
            setWalletNfts(data);
          }else{
            notify.show(message, "error");
          }
        })
      }
      setMainNet(user.main_net)
    }
    
  },[user, cookies, cookies.Dimenxion, setCookie, publicKey,account])
  const handlePlay = ()=>{
    const url = `https://app.dimenxion.io/?uid=${cookies.Dimenxion}`
    window.open(url, '_blank', 'noopener,noreferrer');

  }
  const selecteStakeHendler = (stakeData) => {
    if (stakeData && stakeData.id) {
      let isExist = selectedStake.find(
        (stake, index) => stake.id === stakeData.id
      );
      console.log(isExist, "isExist");
      if (isExist && isExist.id) {
        let newSelectedStake = selectedStake.filter(
          (stake, index) => stake.id !== stakeData.id
        );
        setSelectedStake(newSelectedStake);
      } else {
        setSelectedStake([...selectedStake, stakeData]);
      }
    }
  };
  const selecteStakeNftsHendler = (stakeData) => {
    if (stakeData && stakeData.id) {
      let isExist = selectedStakeNfts.find(
        (stake, index) => stake.id === stakeData.id
      );
      console.log(isExist, "isExist");
      if (isExist && isExist.id) {
        let newSelectedStake = selectedStakeNfts.filter(
          (stake, index) => stake.id !== stakeData.id
        );
        setSelectedStakeNfts(newSelectedStake);
      } else {
        setSelectedStakeNfts([...selectedStakeNfts, stakeData]);
      }
    }
  };
  const user_id = useAppSelector((state) => state.user.data.id);
  //const { connection } = useConnection();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const connection = new Connection("https://small-proud-resonance.solana-mainnet.quiknode.pro/1c494c9737446a6f91379f3e16aad186ab3fbc45/")
  
  
  

  

  

  const handleSendTransaction = useCallback(async () => {
    if (!publicKey) throw new WalletNotConnectedError();
    const transformNfts = ()=>{
      let tokens = ""
      let arr =selectedStakeNfts.map((stake, index) => stake.id);
      arr.map((val)=>{
        tokens = tokens + val + ","
        return null
      })
      console.log("Arr" , tokens)
      return tokens
    }
   
    
    AuthApi.depositNftsTx(user_id,transformNfts(),publicKey.toString()).then(async (response)=>{
      const { value,message,tx } = response.data;
      console.log("resp Dep",response.data)
      if (value === 1){
        const message_tx: Message = Message.from(Buffer.from(tx));
        const transaction: Transaction = Transaction.populate(message_tx);
        transaction.recentBlockhash = (
          await connection.getLatestBlockhash('confirmed')
        ).blockhash;
        try {
          const signature: string = await sendTransaction(
            transaction,
            connection,
          );
          await connection.confirmTransaction(signature, 'confirmed');
          notify.show("Deposited Nfts Successfully","success");
          
        } catch (error) {
          if (error.code === 4001) {
            notify.show(t("escrow.userRejectTheRequest"), "error");
          } else {
            notify.show(error.message, "error");
          }
        }
        
        
      }else{
        notify.show(message, "error");
      }

    })
  }, [publicKey, user_id, selectedStakeNfts, connection, sendTransaction, t]);


  
  const handleSendTransactionPoly = useCallback(async () => {
    if (!account) throw new WalletNotConnectedError();
    const transformNfts = ()=>{
      let tokens = ""
      let arr =selectedStakeNfts.map((stake, index) => stake.id);
      arr.map((val)=>{
        tokens = tokens + val + ","
        return null
      })
      console.log("Arr" , tokens)
      return tokens
    }
   
    
    AuthApi.depositNftsTxPoly(user_id,transformNfts(),account.toString()).then(async (response)=>{
      const { value,message,tx } = response.data;
      console.log("resp Dep",response.data)
      if (value === 1){
        
        try {
          const signature = await provider.getSigner().sendTransaction(tx)
          signature.wait()
          //await connection.confirmTransaction(signature, 'confirmed');
      } catch (error) {
          if (error.code === 4001) {
              notify.show(t("escrow.userRejectTheRequest"), "error");
            } else {
              notify.show(error.message, "error");
            }
      }
        
      }else{
        notify.show(message, "error");
      }

    })
  }, [publicKey, user_id, selectedStakeNfts, connection, sendTransaction, t]);

  const withdrawNfts = useCallback(async () => {
    if (!publicKey) throw new WalletNotConnectedError();
    
    const transformNfts = ()=>{
      let tokens = ""
      let arr = selectedStake.map((stake, index) => stake.id);
      arr.map((val)=>{
        tokens = tokens + val + ","
        return null
      })
      console.log("Arr" , tokens)
      return tokens
    }
    var enc = new TextEncoder();
    const msg = await signMessage(enc.encode(AuthApi.message_withdraw_nfts(user_id,main_wallet)))
    //const { binary_to_base58 } = require('base58-js')
    const api_str = Buffer.from(msg).toString('hex')
    
    AuthApi.withdrawNfts(user_id,transformNfts(),publicKey.toString(),api_str).then((response)=>{
      const { value, message,tx_hash } = response.data;
      if (value === 1) {
        setTransaction(`https://solscan.io/tx/${tx_hash}`);
        notify.show(transaction,"success");
      } else {
        notify.show(message, "error");
      }

    }).catch((error) => {
      console.log(error);
    });
  }, [main_wallet, publicKey, selectedStake, signMessage, user_id,tabValue]);

  
  const withdrawNftsPoly = useCallback(async () => {
    if (!account) throw new WalletNotConnectedError();
    
    const transformNfts = ()=>{
      let tokens = ""
      let arr = selectedStake.map((stake, index) => stake.id);
      arr.map((val)=>{
        tokens = tokens + val + ","
        return null
      })
      console.log("Arr" , tokens)
      return tokens
    }
    var enc = new TextEncoder();
    const msg = await provider.getSigner().signMessage(enc.encode(AuthApi.message_withdraw_nfts(user_id,main_wallet_eth)))
    //const { binary_to_base58 } = require('base58-js')
    const api_str = msg
    
    AuthApi.withdrawNftsPoly(user_id,transformNfts(),account.toString(),api_str).then((response)=>{
      const { value, message,tx_hash } = response.data;
      if (value === 1) {
        
        notify.show("success");
      } else {
        notify.show(message, "error");
      }

    }).catch((error) => {
      console.log(error);
    });
  }, [main_wallet, publicKey, selectedStake, signMessage, user_id,tabValue]);

  
  return (
  <>
    {user.id && cookies.Dimenxion ? (
    <>
    <br/>
    <br/>
    {isDesktop&&(<div style={{alignItems:"center",textAlign:'center',display:'flex',justifyContent:'center'}} ><DiscordAuth /></div>)}              
      <br/>
      <div className="mini-page">
        <h2 style={{textAlign:'center'}}>Choose a network :</h2>
        <div style={{justifyContent:"center",display:"inline-flex"}}>
          <div> 
            <div style={{position: 'relative', display: 'inline-block'}}> 
              <button className="btn-bg bg-transparent border-0 fs-sm text-white justify-content-center" onClick={()=>{setTabValue("Solana")}}>Solana</button> 
              {tabValue === "Solana" ? (
                <span style={{
                        content: '""',
                        position: 'absolute',
                        left: 0,
                        bottom: -10,
                        height: 5,
                        width: '90%',
                        backgroundColor: '#6610f2',
                        display: 'block',
                      }} />
              ):(<></>)} 
            </div>
            <div style={{position: 'relative', display: 'inline-block'}}> 
              <button className="btn-bg bg-transparent border-0 fs-sm text-white justify-content-center" onClick={()=>{setTabValue("Polygon")}}>Polygon</button> 
              {tabValue === "Polygon" ? (
                <span style={{
                          content: '""',
                          position: 'absolute',
                          left: 0,
                          bottom: -10,
                          height: 5,
                          width: '90%',
                          backgroundColor: '#6610f2',
                          display: 'block',
                      }} />
            ):(<></>)} 
            </div>
          </div>
          
        </div>
      </div>
      <br/>
      <div className="mini-page">
        <div className="container">
          <div className="row justify-content-center text-center">
            <div className="col-sm-10 col-12 py-5">
              <div className="mini-page-card gradient-border ">
                <div>
                  <h2 style={{textAlign:'center',position: 'relative', display: 'inline-block'}}>
                      Prefered Network
                  <span style={{
                    content: '""',
                    position: 'absolute',
                    left: 0,
                    bottom: -5,
                    height: 2,
                    width: '100%',
                    backgroundColor: '#6610f2',
                    display: 'block',
                  }} />
                
                  </h2>
                  <br/>
                  <br/>
                  {(mainNet == "solana") && (<>
                    <h3 style={{display:'inline-flex',alignItems:'center',alignContent:'center'}}  > Selected :   Solana &nbsp;&nbsp;
                     <img className="logo-solana"
                      src={logo}
                      alt="solana"
                    /></h3>
                    <br/><br/>
                    <button className="btn-bg bg-transparent border-0 fs-sm text-white justify-content-center" onClick={()=>{handleSetPref("poly")}}>Set Polygon</button>
                    <br/><br/>
                  </>)}
                  {(mainNet == "poly") && (<>
                    <h3 style={{display:'inline-flex',alignItems:'center',alignContent:'center'}}  > Selected :   Polygon &nbsp;&nbsp;
                     <img className="logo-solana"
                      src={logoPoly}
                      alt="solana"
                    /></h3>
                    <br/><br/>
                    <button className="btn-bg bg-transparent border-0 fs-sm text-white justify-content-center" onClick={()=>{handleSetPref("solana")}}>Set Solana</button>
                    <br/><br/>
                  </>)}
                  
                  <div className="list-wallets">
                    The prefered network let the player choose where they want to withdraw there winning from the game ! <br/> It can be changed any time ! 
                  </div>





                </div>
                <br/>
                <br/>

                {tabValue === "Solana"&&(<>
                
                <h2 style={{textAlign:'center',position: 'relative', display: 'inline-block'}}>
                  Wallets
                  <span style={{
                    content: '""',
                    position: 'absolute',
                    left: 0,
                    bottom: -5,
                    height: 2,
                    width: '100%',
                    backgroundColor: '#6610f2',
                    display: 'block',
                  }} />
                
                </h2>
                <div className="mini-page">
                  <div className="container">
                    <div className="row justify-content-center text-center">
                      <div className="col-sm-10 col-12 py-5">
                        <div className="mini-page-card gradient-border ">
                            
                            <Wallets/>
                            <ConnectWallet/>
                            <br/>
                            <div className="list-wallets">
                              Once accessed, you can send assets in your inventory through the game to other connected players, and more. These items will be linked to both your account and your experience!
                            </div>

                        </div>
                      </div>
                    </div>    
                  </div>
                </div>
                  
                <br/> 
                <h2 style={{textAlign:'center',position: 'relative', display: 'inline-block'}}>
                  Game Inventory
                  <span style={{
                    content: '""',
                    position: 'absolute',
                    left: 0,
                    bottom: -5,
                    height: 2,
                    width: '100%',
                    backgroundColor: '#6610f2',
                    display: 'block',
                  }} />
                
                </h2>
                <div className="mini-page">
                  <div className="container">
                    <div className="row justify-content-center text-center">
                      <div className="col-sm-10 col-12 py-5">
                        <div className="mini-page-card gradient-border ">
                            <EscrowDetails/>
                            <br/>
                            <br/>
                            <hr/>
                            <h3 style={{display:'inline-flex',alignItems:'center',alignContent:'center'}}  > NFTs </h3> <hr/> <br/>
                            <Stake
                              userData={{ nft_to_stake: escrowNfts }}
                              selecteStakeHendler={selecteStakeHendler}
                              selectedStake={selectedStake}
                              public={publicKey}
                            />
                            <Button
                              className="btn-bg bg-transparent border-0 fs-sm text-white justify-content-center"
                              variant="contained"
                              disableElevation
                              onClick={withdrawNfts}
                              disabled={!publicKey || (selectedStake.length<0)}
                              sx={{mb:"20px"}}
                            >
                              Withdraw Selected Nfts to main Wallet
                            </Button>
                            <br/>
                            <UnStake
                              userData={{staked_nfts:walletNfts}}
                              selecteStakeHendler={selecteStakeNftsHendler}
                              selectedStake={selectedStakeNfts}
                            />
                            
                            <Button
                              className="btn-bg bg-transparent border-0 fs-sm text-white justify-content-center"
                              variant="contained"
                              disableElevation
                              onClick={handleSendTransaction}
                              disabled={(selectedStakeNfts.length<0)}
                            >
                              Deposit Nfts to Escrow
                            </Button>
                        </div>
                        </div>
                      </div>
                    </div>    
                  </div>
                  </>)} 

                  {tabValue==="Polygon"&&(<>
                    <h2 style={{textAlign:'center',position: 'relative', display: 'inline-block'}}>
                      Wallets
                      <span style={{
                        content: '""',
                        position: 'absolute',
                        left: 0,
                        bottom: -5,
                        height: 2,
                        width: '100%',
                        backgroundColor: '#6610f2',
                        display: 'block',
                      }} />
                    
                    </h2>
                    <div className="mini-page">
                      <div className="container">
                        <div className="row justify-content-center text-center">
                          <div className="col-sm-10 col-12 py-5">
                            <div className="mini-page-card gradient-border ">
                              <hr/><p > Connect a Wallet : </p><hr/>
                                <ConnectWalletEth/>
                                <br/>
                                <WalletsEth/>
                                <br/>
                                <div className="list-wallets">
                                  Once accessed, you can send assets in your inventory through the game to other connected players, and more. These items will be linked to both your account and your experience!
                                </div>
                            </div>
                          </div>
                        </div>    
                      </div>
                    </div>
                    <br/> 
                    <h2 style={{textAlign:'center',position: 'relative', display: 'inline-block'}}>
                      Game Inventory
                      <span style={{
                        content: '""',
                        position: 'absolute',
                        left: 0,
                        bottom: -5,
                        height: 2,
                        width: '100%',
                        backgroundColor: '#6610f2',
                        display: 'block',
                      }} />
                      </h2>
                      <div className="mini-page">
                        <div className="container">
                          <div className="row justify-content-center text-center">
                            <div className="col-sm-10 col-12 py-5">
                              <div className="mini-page-card gradient-border ">
                              <EscrowDetailsEth/>
                              <br/>
                            <br/>
                            <hr/>
                            <h3 style={{display:'inline-flex',alignItems:'center',alignContent:'center'}}  > NFTs </h3> <hr/> <br/>
                            <Stake
                              userData={{ nft_to_stake: escrowNftsPoly }}
                              selecteStakeHendler={selecteStakeHendler}
                              selectedStake={selectedStake}
                              public={account}
                            />
                            <Button
                              className="btn-bg bg-transparent border-0 fs-sm text-white justify-content-center"
                              variant="contained"
                              disableElevation
                              onClick={withdrawNftsPoly}
                              disabled={!account || (selectedStake.length<0)}
                              sx={{mb:"20px"}}
                            >
                              Withdraw Selected Nft to main Wallet
                            </Button>
                            <br/>
                            <UnStake
                              userData={{staked_nfts:walletNftsPoly}}
                              selecteStakeHendler={selecteStakeNftsHendler}
                              selectedStake={selectedStakeNfts}
                            />
                            
                            <Button
                              className="btn-bg bg-transparent border-0 fs-sm text-white justify-content-center"
                              variant="contained"
                              disableElevation
                              onClick={handleSendTransactionPoly}
                              disabled={(selectedStakeNfts.length<0)}
                            >
                              Deposit Nft to Escrow
                            </Button>
                              </div>
                            </div>
                          </div>
                        </div>    
                      </div>





                  </>)}
                  </div>
              </div>
              
          </div>    
        </div>
      </div>
      
        <br/> 
        <div style={{alignItems:"center",textAlign:'center',display:'flex',justifyContent:'center'}}>
          <button className="btn-bg bg-transparent border-0 fs-sm text-white justify-content-center" onClick={handlePlay}>Play Dimenxion !</button>
        </div>
    </>
    ):(<>
      <div className="mini-page">
        <div className="container">
          <div className="row justify-content-center text-center">
            <div className="col-sm-10 col-12 py-5">
              <div className="mini-page-card gradient-border ">
              <p>With your inventory secured, you’re able to explore our composable metaverse with your friends 
                as well as participate in events, hold items and more!</p>
                <div className="list-wallets">1. After Securing Your Secret Pass Phrases, Connect Your Wallet</div>
                <div className="list-wallets">2. After Enabling 2FA, Connect Your Discord or Your Google Account</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br/>
      <br/>
      <div className="mini-page">
        <div className="container">
          <div className="row justify-content-center text-center">
            <div className="col-sm-10 col-12 py-5">
              <div className="mini-page-card gradient-border ">
                {cookies.Dimenxion?(<p> Please Wait a Second Fetching your Profile ! </p>):(<>
                  <h2 >Please Login First.</h2>
                  <br/>
                  <Login/>
                  </>
                )}
                
              </div>
            </div>
          </div>
        </div>
      </div>
      </>
    )}
    
  </>
  );
};

export default Escrow;
