import { useCallback, useEffect } from 'react'
import { useAppDispatch } from './useAppStore';
import { getUserAsync } from '../store/userSlice';
import { useCookies } from 'react-cookie';
function useUser() {
  const [cookies, setCookie] = useCookies(['Dimenxion']);
 
  const dispatch = useAppDispatch();

  const getUser = useCallback((user_id:string) => {

    dispatch(getUserAsync(user_id))
  }, [dispatch]);

  useEffect(() => {
    

    if (cookies.Dimenxion){
      console.log('here in yes')
      getUser(cookies.Dimenxion);
    }
    
  }, [getUser, cookies,cookies.Dimenxion,setCookie])

}

export default useUser;