import React from "react";
import logoimg from "./img/logo.png";
import { DiscordIcon, InstaIcon, TwitterIcon } from "./Icons";
import purple from "./img/pink-bg-img.png";

const Footer = () => {
  return (
    //   FOOTER SECTION
    <div>
    <section className="footer-section position-relative pt-5 pb-3">
      <div>
        <img className="d-block mx-auto" src={logoimg} alt="logoimg" />
      </div>
      <div className="text-center mt-4 position-relative z-5">
        <a href="https://twitter.com/Dimenxion_io">
          {" "}
          <TwitterIcon />
        </a>
        <a href="https://www.instagram.com/dimenxion.io/">
          <InstaIcon />
        </a>
        <a href="https://discord.gg/xvpPVNhxQA">
          <DiscordIcon />
        </a>
      </div>
      <div className="footer-border my-4"></div>
      <p className="copyright-text mb-0 pb-3 text-center">
        Copyright@Dimenxion.io
      </p>
      <img className="purple-img " src={purple} alt="shade-purple" />
    </section>
    </div>
  );
};

export default Footer;
