import { useState, ChangeEvent, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useWallet } from "@solana/wallet-adapter-react";
import { notify } from "react-notify-toast";

import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import Typography from "@mui/material/Typography";
import AuthApi from "./DiscordConst";
import Loader from "./Loader";
import { WalletNotConnectedError } from "@solana/wallet-adapter-base";
import { useAppSelector } from "hooks";
import HomeIcon from '@mui/icons-material/AccountBalanceWalletOutlined';

interface Token {
  token:string;
}

function Withdraw({token}:Token) {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);
  const [amount, setAmount] = useState("");
  const [transaction, setTransaction] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const user_id = useAppSelector((state) => state.user.data.id);
  const main_wallet = useAppSelector((state) => state.user.data.main_wallet);
  const { publicKey ,signMessage} = useWallet();

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
    setTransaction(null);
    setAmount("");
  };

  const handleAmountChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setAmount(value);
  };

  const withdrawSOL = useCallback(async () => {
    if (!publicKey) throw new WalletNotConnectedError();
    if (amount === "" || amount === "0" || Number(amount) <= 0) {
      notify.show(t("escrow.amountMustBeAbove0"), "error", 20000);
      return;
    }
    var enc = new TextEncoder();
    const msg = await signMessage(enc.encode(AuthApi.message_withdraw()))
    //const { binary_to_base58 } = require('base58-js')
    const api_str = Buffer.from(msg).toString('hex')
    setIsLoading(true);
    AuthApi.withdraw(user_id,token,api_str,amount.toString()).then((response)=>{
      const { value, message,tx_hash } = response.data;
      if (value === 1) {
        setTransaction(`https://solscan.io/tx/${tx_hash}`);
      } else {
        notify.show(message, "error");
      }
      setIsLoading(false);

    }).catch((error) => {
      setIsLoading(false);
      console.log(error);
    });
  }, [amount, publicKey, signMessage, t, token, user_id]);

  const isMainWallet = useCallback(()=>{
    if (!publicKey) return false
    return publicKey.toString() === main_wallet
  },[main_wallet, publicKey])

  return (
    <>
      <Button
        variant="contained"
        disableElevation
        onClick={openModal}
        disabled={!publicKey}
      >
        Withdraw {token}
      </Button>
      <Modal
        open={isOpen}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {isMainWallet()?(
        <Box className="modal-box">
          <Typography id="modal-modal-title" variant="h6" component="h2" className="modal-box__details-title">
            Withdraw {token}
          </Typography>

          {isLoading && <Loader />}

          {!isLoading && (
            <>
              {!transaction && (
                <>
                  <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    {t("escrow.pleaseSpecifyTheAmount")}
                  </Typography>

                  <Box sx={{ mt: 2 }}>
                    <OutlinedInput
                      name="price"
                      placeholder={t("escrow.amount")}
                      value={amount}
                      onChange={handleAmountChange}
                      type="number"
                      size="small"
                      componentsProps={{
                        input: {
                          min: 0,
                        },
                      }}
                    />
                  </Box>

                  <Box sx={{ mt: 2 }}>
                    <Button
                      variant="contained"
                      disableElevation
                      onClick={withdrawSOL}
                    >
                      {t("escrow.withdraw")}
                    </Button>
                  </Box>
                </>
              )}

              {transaction && (
                <Typography sx={{ mt: 2 }}>
                  <a href={transaction} target="_blank" rel="noreferrer">
                    <Button variant="contained" disableElevation>
                      {t("escrow.checkTheTransaction")}
                    </Button>
                  </a>
                </Typography>
              )}
            </>
          )}
        </Box>):(
          <Box className="modal-box">
          <p>Please Connect your Main Wallet to be able to withdraw or set the current Wallet as Main by clicking the icone : &nbsp; <HomeIcon /></p>
          </Box>
        )}
        
      </Modal>
    </>
  );
}

export default Withdraw;
