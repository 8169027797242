import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { notify } from 'react-notify-toast';
import i18n from "i18next";


import Api from 'api';

import { setHistoryWatchList } from './historyWatchListSlice';
import { setWatchList } from './watchListSlice';

type userState = {
  isLoading: boolean,
  data: {
    id: string;
    avatar: string;
    username: string;
    balances: any;
    wallets: Array<string>;
    main_wallet: string;
    main_wallet_eth: string;
    wallets_eth: Array<string>;
    main_net : string;
  }
};



const initialState: userState = {
  isLoading: false,
  data: {
    id: null,
    avatar: null,
    username: null,
    balances: null,
    wallets: null,
    main_wallet : null,
    main_wallet_eth : null,
    wallets_eth : null,
    main_net : null,
  }
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserRequest: (state) => {
      state.isLoading = true;
      return state
    },

    setUserFailure: (state) => {
      state.isLoading = false;
      return state
    },

    setUserSuccess: (state, action: PayloadAction<userState["data"]>) => {
      state.isLoading = false;
      state.data = action.payload

      return state;
    },
  }
})



export const getUserAsync = (user_id : string) => (dispatch) => {

  dispatch(setUserRequest())
  
  
  
  if (user_id != null){
    Api.getUserDiscord(user_id).then((response)=>{
      
      const { value, data, message } = response.data;
      console.log('In Get User')
      console.log(value,'Val')
      if (value !== 1) {
        //notify.show(message, "error");
        dispatch(setUserFailure());
        return;
      }

      
      
      console.log(data,'id_in_async')
      
        
      
      dispatch(setUserSuccess({ 
        balances: data.balances,
        id: data.id,
        avatar: data.avatar,
        username: data.username,
        wallets: data.wallets,
        main_wallet : data.main_wallet,
        main_wallet_eth: data.main_wallet_eth,
        wallets_eth : data.wallets_eth,
        main_net : data.main_net
        }));
      //dispatch(setWatchList(data.watch_list))
      //dispatch(setHistoryWatchList(data.history_watch_list))
      
      
  
    }).catch((error: Error) => {
      console.log(error);
      //dispatch(setUserFailure());
      //notify.show(i18n.t('errors.somethingWrong'), "error");
    })
  }else{

  }
  
  
}



export const { setUserSuccess, setUserRequest, setUserFailure } = userSlice.actions
export default userSlice.reducer
