import { createContext } from 'react';

export type ThemeType = "dark" | "light";

export const THEMES = {
  light: "light" as const,
  dark: "dark" as const,
}

const ThemeContext = createContext<{
  theme: ThemeType,
  switchTheme: () => void,
}>({
  theme: "dark",
  switchTheme: () => { },
});

export default ThemeContext