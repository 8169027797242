import { useState, useCallback, ChangeEvent } from "react";
import { notify } from "react-notify-toast";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import OutlinedInput from "@mui/material/OutlinedInput";
import Button from "@mui/material/Button";
import AuthApi from "./DiscordConst";
import { useAppSelector} from "hooks";
import { useAppDispatch } from 'hooks/useAppStore';
import { getUserAsync } from 'store/userSlice';
import { useWeb3React } from '@web3-react/core';
interface Token {
  token:string;
}


function Deposit({token}:Token) {
  const {account, isActive ,provider} = useWeb3React()
  const [isOpen, setIsOpen] = useState(false);
  const [amount, setAmount] = useState("");

  const { t } = useTranslation();

  const user_id = useAppSelector((state) => state.user.data.id);
  //const { connection } = useConnection();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  
  const dispatch = useAppDispatch();
  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
    setAmount("");
  };

  const handleAmountChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setAmount(value);
  };

  const handleSendTransaction = useCallback(async () => {
    //if (!account) throw new WalletNotConnectedError();

    if (amount === "" || amount === "0" || Number(amount) <= 0) {
      notify.show(t("escrow.amountMustBeAbove0"), "error", 20000);
      return;
    }

    AuthApi.depositTx_eth(user_id,account.toString(),token,amount.toString()).then(async (response)=>{
        
        const { value,message,tx } = response.data;
      if (value === 1){
        console.log(tx)
        
        
        
        try {
            const signature = await provider.getSigner().sendTransaction(tx)
            signature.wait()
            //await connection.confirmTransaction(signature, 'confirmed');
          closeModal();
          dispatch(getUserAsync(user_id));
        } catch (error) {
            if (error.code === 4001) {
                notify.show(t("escrow.userRejectTheRequest"), "error");
              } else {
                notify.show(error.message, "error");
              }
        }
        
        
      }else{
        notify.show(message, "error");
      }

    })
  }, [account, amount, user_id, token, t, dispatch]);

 
  return (
    <>
      <Button
        variant="contained"
        disableElevation
        onClick={openModal}
        disabled={!isActive}
      >
        Deposit {token} to Escrow
      </Button>
      <Modal
        open={isOpen}
        onClose={closeModal}
        // className="modal-box"
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modal-box">
          <div>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              className="modal-box__details-title"
            >
              Deposit {token} to Escrow
            </Typography>
            <Typography
              id="modal-modal-description"
              sx={{ mt: 2 }}
              className="modal-box__details-title"
            >
              {t("escrow.pleaseSpecifyTheAmount")}
            </Typography>

            <Box sx={{ mt: 2 }}>
              <OutlinedInput
                name="price"
                placeholder={t("escrow.amount")}
                value={amount}
                onChange={handleAmountChange}
                type="number"
                // disabled={!account}
                size="small"
                componentsProps={{
                  input: {
                    min: 0,
                  },
                }}
              />
            </Box>

            <Box sx={{ mt: 2 }}>
              <Button
                variant="contained"
                disableElevation
                onClick={handleSendTransaction}
              >
                {t("escrow.deposit")}
              </Button>
            </Box>
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default Deposit;
